<template>
  <div class="new__table new__table_2" v-loading="loadingData">
    <div class="scheduler">
      <div class="schedule">
        <div class="crm-schedule-box d-inline-flex w-100">
          <table
            class="table table-bordered nurseOffice_table crm-schedule-table table-bordered"
          >
            <thead>
              <tr>
                <th>{{ $t("message.physiotherapy") }}</th>
                <th v-for="date in dateList" :key="date.val + '-datess'">
                  {{ date.title }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="physiotherapy in list" :key="physiotherapy.id">
                <td class="left_likarstovas">
                  <div class="modalCells">
                    {{
                      physiotherapy.hospitalizationService
                        ? physiotherapy.hospitalizationService.name
                        : ""
                    }}
                  </div>
                </td>
                <td
                  v-for="date in dateList"
                  :key="date.val + '-dat  '"
                  class="dataDay"
                >
                  <template v-if="hasLek(date, physiotherapy)">
                    <template v-if="physiotherapy.is_edit == 1">
                      <template v-for="i in physiotherapy.count">
                        <div
                          v-if="lastHistory.is_ended != 0"
                          class="modalCells is_edit"
                          :class="classObject(date, physiotherapy, i)"
                          :key="i + '-count'"
                        ></div>
                        <div
                          v-else
                          class="modalCells is_edit"
                          :class="classObject(date, physiotherapy, i)"
                          :key="i + '-count'"
                          @click="openDialog(date, physiotherapy, i)"
                        ></div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="i in physiotherapy.count">
                        <div
                          v-if="lastHistory.is_ended != 0"
                          class="modalCells"
                          :class="classObject(date, physiotherapy, i)"
                          :key="i + '-count'"
                        ></div>
                        <div
                          v-else
                          class="modalCells"
                          :class="classObject(date, physiotherapy, i)"
                          :key="i + '-count'"
                          @click="openDialog(date, physiotherapy, i)"
                        ></div>
                      </template>
                    </template>
                  </template>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <el-dialog
        class="modal_lea xModal"
        title="Информация для лечения"
        width="30%"
        :visible.sync="dialogTableVisible2"
        @closed="closeDialog()"
        @opened="$refs['modalAdd'].afterOpened()"
      >
        <ModalAdd
          :selected="selectedModel_2"
          ref="modalAdd"
          @c-close="closeDialog"
        />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ModalAdd from "./components/modalAdd";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  props: ["number", "isended"],
  name: "physiotherapy",
  components: {
    ModalAdd,
  },
  data() {
    return {
      loadingData: false,
      isLoadingItems: false,
      dialogTableVisible2: false,
      isItemsModelVisible: false,
      selectedModel: {},
      selectedModel_2: {},
      filterForm: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      indexList: "physiotherapyIndeces/list",
      list: "nurceOfficePhies/list",
      dateList: "nurceOfficePhies/dateList",
      columns: "nurceOfficePhies/columns",
      pagination: "nurceOfficePhies/pagination",
      filter: "nurceOfficePhies/filter",
      sort: "nurceOfficePhies/sort",
      physiotherapies: "nurceOfficePhies/model",
      lastHistory: "dailyTreatments/lastHistory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    $(".new__table_2 .crm-schedule-table tbody").scroll(function (e) {
      //detect a scroll event on the tbody
      $(".new__table_2 .crm-schedule-table thead").css(
        "left",
        -$(".new__table_2  .crm-schedule-table tbody").scrollLeft()
      ); //fix the thead relative to the body scrolling
      $(".new__table_2 .crm-schedule-table thead th:nth-child(1)").css(
        "left",
        $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
      ); //fix the first cell of the header
      $(".new__table_2 .crm-schedule-table tbody td:nth-child(1)").css(
        "left",
        $(".new__table_2 .crm-schedule-table tbody").scrollLeft()
      ); //fix the first column of tdbody
    });
  },
  methods: {
    ...mapActions({
      updateList: "nurceOfficePhies/index",
      updateSort: "nurceOfficePhies/updateSort",
      updateFilter: "nurceOfficePhies/updateFilter",
      updateColumn: "nurceOfficePhies/updateColumn",
      showDate: "nurceOfficePhies/show",
      updatePagination: "nurceOfficePhies/updatePagination",
      empty: "nurceOfficePhies/empty",
      delete: "nurceOfficePhies/destroy",
      refreshData: "nurceOfficePhies/refreshData",
      showNumber: "nurceOfficePhies/showNumber",
    }),
    hasLek(date, dailyT) {
      if (dailyT.end_date) {
        let days = dailyT.end_date;
        var parts = date.val.split("/");
        var year = parts[0];
        var month = parts[1];
        var day = parts[2];
        var newDateString = day + "." + month + "." + year;
        if (_.isArray(days) && days.includes(newDateString)) {
          return true;
        }
      }
    },
    fetchData() {},
    fetchDataAny() {
      this.loadingData = true;
      this.showNumber({ number: this.number })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    classObject: function (date, dt, i) {
      let a = this.checkIndex(date, dt, i);
      return [
        {
          on_lics: a == "plus" || a == "continue",
          od_lics: a == "minus",
          off_lics: a == "defect",
          off_user: a == "reject",
        },
        "height" + dt.count,
      ];
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    openDialog(date, dt, time) {
      console.log(dt.physiotherapyIndeces);
      if (dt.physiotherapyIndeces) {
        let days = dt.physiotherapyIndeces;
        if (_.isArray(days)) {
          let item = _.find(days, function (o) {
            return o.day == date.val && o.time == time;
          });
          if (item) {
            this.dialogTableVisible2 = true;
            this.selectedModel_2 = item;
          }
        }
      }
    },
    closeDialog() {
      this.dialogTableVisible2 = false;
      this.fetchDataAny();
    },
    checkIndex(date, dt, i) {
      if (dt.physiotherapyIndeces) {
        let indeces = dt.physiotherapyIndeces;
        if (_.isArray(indeces)) {
          let item = _.find(indeces, function (o) {
            return o.day == date.val && o.time == i;
          });
          return item ? item.index : "minus";
        }
      }
    },
    showItems(model) {
      this.isItemsModelVisible = true;
      this.selectedModel = model;
    },
    onCloseDialog(done) {
      done();
      this.empty();
      this.isLoadingItems = false;
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss">
.input_plusc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-icon-plus {
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    height: 32px;
    line-height: 30px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #dcdfe6;
    cursor: pointer;
  }
}

.el-form-item {
  flex: 1;
}

.minheight .el-textarea__inner {
  min-height: 100px !important;
}

.add__likarstova {
  padding: 15px 15px 0px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.add_pol {
  text-align: center;
  color: #3a90c3;
  font-size: 15px;
  cursor: pointer;
}

.indicators__table .el-checkbox__label {
  font-size: 14px !important;
  color: #303030 !important;
}

.indicators__table th {
  font-size: 16px !important;
  color: #303030 !important;
}

.add_close {
  position: absolute;
  top: -14px;
  right: -11px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.add_close_2 {
  position: absolute;
  top: 3px;
  right: 4px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.color_p4 {
  margin-top: -10px;
  margin-bottom: 12px;
  font-size: 15px;
}

.button_r_2 {
  text-align: right;
  margin-top: -26px;
}
</style>